import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';

function Loading({ style }) {
	return (
		<div className='text-center'>
			<Spinner
				animation='grow'
				variant='danger'
				style={{ marginTop: '40%', ...style }}
			/>
			<p>Loading.....</p>
		</div>
	);
}

Loading.propTypes = {
	style: PropTypes.object,
};

Loading.defaultProps = {
	style: {},
};

export default Loading;
